$(function() {
  // Large image
  $('body').on("click", ".open-photo-modal", function(e) {
    var src = $(e.currentTarget).attr('data-src');
    $('.photo-cortina').show();
    $('.photo-modal').show().find('img').attr('src', src);
  });
  // Clone
  $('body').on("click", ".photo-modal-close", function() {
    $('.photo-cortina').fadeOut();
    $('.photo-modal').fadeOut(0).find('img').attr('src', '')
  });
});
