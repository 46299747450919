$.fn.serializeCleanVal = function() {
   var o = {};
   var a = $('input, select', this);
   $.each(a, function() {
     if (o[this.name]) {
       if (!o[this.name].push) {
         o[this.name] = [o[this.name]];
       }
       if(typeof $(this).data('mask') != "undefined")
         o[this.name].push($(this).cleanVal() || '');
       else
         o[this.name].push(this.value || '');
     } else {
       if(typeof $(this).data('mask') != "undefined")
         o[this.name] = $(this).cleanVal() || '';
       else
         o[this.name] = this.value || '';
     }
   });
   return o;
};
