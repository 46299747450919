$(function() {
  $("#form-login").send(function(data) {
    if (data.status)
      location.href = "";
    else {
      $.dialog.info("Erro ao realizar login", data.msg);
      $("[type='password']", "#form-login").val('');
    }
  }, error_send);

  $("#form-forgot").send(function(data) {
    if (data.status)
      $.dialog.info("Recuperar senha", "Um email para recuperação foi enviado para <b>"+ data.email +'</b>');
    else {
      $.dialog.info('Erro', data.message);
    }
  }, error_send);

  $("body").on("click", ".showPassword", function() {
    if ($(this).attr("show") == 0) {
      $(this).html("&#xE8F5;").attr("show", 1);
      $(this).prev("input").attr("type", "text");
    } else {
      $(this).html("&#xE8F4;").attr("show", 0);
      $(this).prev("input").attr("type", "password");
    }
  });
});
