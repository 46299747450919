
function validateESUS(vlrCNS) {
	vlrCNS = vlrCNS.replace(/\D/g, '');
	// console.log(vlrCNS);
	if ([1,2].indexOf(parseInt(vlrCNS.substring(0,1))) != -1)
		return validaCNS(vlrCNS);
	else
		return ValidaCNS_PROV(vlrCNS);
}

// CPF
function validateCPF(cpf) {
	cpf = cpf.replace(/\D/g, '');
	if(cpf.length != 11 || cpf.replace(eval('/'+cpf.charAt(1)+'/g'),'') == '')
	{
		return false;
	}
	else
	{
		for(n=9; n<11; n++)
		{
			for(d=0, c=0; c<n; c++) d += cpf.charAt(c) * ((n + 1) - c);
				d = ((10 * d) % 11) % 10;

			if(cpf.charAt(c) != d) return false;
		}
		return true;
	}
}

function ValidaCNS_PROV(vlrCNS)
{
	var pis;
	var resto;
	var dv;
	var soma;
	var resultado;
	var result = 0;

	pis = vlrCNS.substring(0,15);

	if (pis == "") {
		return false
	}

	if ( (vlrCNS.substring(0,1) != "7")  && (vlrCNS.substring(0,1) != "8") && (vlrCNS.substring(0,1) != "9") ) {
		return false
	}

	soma = ((parseInt(pis.substring( 0, 1),10)) * 15)
		+ ((parseInt(pis.substring( 1, 2),10)) * 14)
		+ ((parseInt(pis.substring( 2, 3),10)) * 13)
		+ ((parseInt(pis.substring( 3, 4),10)) * 12)
		+ ((parseInt(pis.substring( 4, 5),10)) * 11)
		+ ((parseInt(pis.substring( 5, 6),10)) * 10)
		+ ((parseInt(pis.substring( 6, 7),10)) * 9)
		+ ((parseInt(pis.substring( 7, 8),10)) * 8)
		+ ((parseInt(pis.substring( 8, 9),10)) * 7)
		+ ((parseInt(pis.substring( 9,10),10)) * 6)
		+ ((parseInt(pis.substring(10,11),10)) * 5)
		+ ((parseInt(pis.substring(11,12),10)) * 4)
		+ ((parseInt(pis.substring(12,13),10)) * 3)
		+ ((parseInt(pis.substring(13,14),10)) * 2)
		+ ((parseInt(pis.substring(14,15),10)) * 1);

	resto = soma % 11;

	if (resto == 0) {
		return true;
	}
	else {
		return false;
	}
}

function validaCNS(vlrCNS) {
	// Formulário que contem o campo CNS
	var soma = new Number;
	var resto = new Number;
	var dv = new Number;
	var pis = new String;
	var resultado = new String;
	var tamCNS = vlrCNS.length;
	if ((tamCNS) != 15) {
		return false;
	}
	pis = vlrCNS.substring(0,11);
	soma = (((Number(pis.substring(0,1))) * 15) +
		((Number(pis.substring(1,2))) * 14) +
		((Number(pis.substring(2,3))) * 13) +
		((Number(pis.substring(3,4))) * 12) +
		((Number(pis.substring(4,5))) * 11) +
		((Number(pis.substring(5,6))) * 10) +
		((Number(pis.substring(6,7))) * 9) +
		((Number(pis.substring(7,8))) * 8) +
		((Number(pis.substring(8,9))) * 7) +
		((Number(pis.substring(9,10))) * 6) +
		((Number(pis.substring(10,11))) * 5));
	resto = soma % 11;
	dv = 11 - resto;
	if (dv == 11) {
		dv = 0;
	}
	if (dv == 10) {
		soma = (((Number(pis.substring(0,1))) * 15) +
			((Number(pis.substring(1,2))) * 14) +
			((Number(pis.substring(2,3))) * 13) +
			((Number(pis.substring(3,4))) * 12) +
			((Number(pis.substring(4,5))) * 11) +
			((Number(pis.substring(5,6))) * 10) +
			((Number(pis.substring(6,7))) * 9) +
			((Number(pis.substring(7,8))) * 8) +
			((Number(pis.substring(8,9))) * 7) +
			((Number(pis.substring(9,10))) * 6) +
			((Number(pis.substring(10,11))) * 5) + 2);
		resto = soma % 11;
		dv = 11 - resto;
		resultado = pis + "001" + String(dv);
	} else {
		resultado = pis + "000" + String(dv);
	}
	if (vlrCNS != resultado) {
		return false;
	} else {
		return true;
	}
};

function getPriorityGroup(individuo) {
	var data = [];
	if(individuo) {
		if(individuo.statusEhGestante == "true") data.push({'group': "gestante", 'name': 'Gestante'});
		if(moment().diff(individuo.dataNascimentoCidadao, "years") < 12) data.push({'group': "crianca", 'name': 'Criança'});
		if(moment().diff(individuo.dataNascimentoCidadao, "years") >= 60) data.push({'group': "idoso", 'name': 'Idoso'});
		if(individuo.statusTemHanseniase == "true") data.push({'group': "hanseniase", 'name': 'Leproso'});
		if(individuo.statusTemHipertensaoArterial == "true") data.push({'group': "hipertenso", 'name': 'Hipertenso'});
		if(individuo.statusTemDiabetes == "true") data.push({'group': "diabetico", 'name': 'Diabético'});
		if(individuo.statusTemTeveCancer == "true") data.push({'group': "cancer", 'name': 'Tem ou teve Câncer'});
	}
	return data;
}

// Remove o indivíduo dos domicílios que ele está relacionado.
// @param Object individuo
function removeResponsibleHome(individuo) {
	var homes_ = getHomeResponsible(individuo);

	if(homes_.length) {
		for(var i in homes_) {
			for (var j in homes_[i].families) {
				if (homes_[i].families[j].numeroCnsResponsavel == individuo.numeroCartaoSus) {
					homes_[i].families.splice(j,1);
					homes.update( homes_[i].__id, { families: homes_[i].families }, true);
					break;
				}
			}
		}
	}
};

//Retorna os domicílios que o responsável está vinculado.
// @param Object individuo;
// @return Array;
function getHomeResponsible(individuo) {
	var homes_ = homes.select();
	var data = [];
	for (var i in homes_) {
		for (var j in homes_[i].families) {
			if (homes_[i].families[j].numeroCnsResponsavel == individuo.numeroCartaoSus){
				data.push(homes_[i]);
			}
		}
	}
	return data;
};

function hasFileSync() {
	var data = null;
	data = individuals.select({'need_update': 1}).length;
	data += homes.select({'need_update': 1}).length;
	data += visits.select({'need_update': 1}).length;
	data += collectiveActivities.select({'need_update': 1}).length;
	data += consumptionMarkers.select({'need_update': 1}).length;
	data += bornAlives.select({'need_update': 1}).length;
	data += diarrheas.select({'need_update': 1}).length;
	data += deaths.select({'need_update': 1}).length;

	if(data > 0) {
		return true;
	}
	return false;
}

//Retorna todos os dependentes de um indivíduo
// @param Object individual;
// @return Array;
function getDependents(individual) {
	return individuals.select({ numeroCartaoSusResponsavel: individual.numeroCartaoSus });
}
